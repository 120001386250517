import React from "react";
import "./totalproducts.scss";
import { useSelector } from "react-redux";
import {
  getCurrenteSearch,
  getPaginationAmount,
  getCurrentProducts,
  getPublishedEntireAmount,
  getPaginationFreeAmount,
} from "@entities";
import { Text } from "@ui-kit";

const TotalProduct = () => {
  const totalAmount = useSelector(getPublishedEntireAmount);
  const searchCurrentValue = useSelector(getCurrenteSearch);
  const paginationAmount = useSelector(getPaginationAmount);
  const products = useSelector(getCurrentProducts);
  const paginationFreeAmount = useSelector(getPaginationFreeAmount) || 0;

  const renderSearchFreeAmount = () => {
    if (paginationFreeAmount) {
      return (
        <Text as="h5">
          <Text as="span">{paginationFreeAmount}</Text> of them are free!
        </Text>
      );
    }
  };

  const renderContent = () => {
    if (searchCurrentValue) {
      const count = products.length ? paginationAmount : 0;

      return (
        <div className="total__wrapper result">
          <Text as="h5" className="mr-6">
            There are <Text as="span">{count}</Text> results for "
            {searchCurrentValue}"{paginationFreeAmount ? "," : ""}
          </Text>
          {renderSearchFreeAmount()}
        </div>
      );
    }

    return (
      <div className="total__wrapper">
        <Text as="h5" className="mr-6">
          There are <Text as="span">{totalAmount}</Text> illustrations now
          {paginationFreeAmount ? "," : ""}
        </Text>
        {renderSearchFreeAmount()}
      </div>
    );
  };

  return renderContent();
};

export default TotalProduct;
