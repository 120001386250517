import { ApiService } from "@shared/api";

class ProductsService extends ApiService {
  baseUrl;
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.baseUrl = "/products";
    this.dashboardUrl = "/dashboard";
  }

  async getProducts(params) {
    return await this.instance.get(
      `${this.baseUrl}/public?${this.generateQueryString(params)}`
    );
  }

  async getHomeGallery() {
    return await this.instance.get(`${this.baseUrl}/public/index`);
  }

  async getSoftProducts(params) {
    return await this.instance.get(
      `${this.baseUrl}/public/soft?${this.generateQueryString(params)}`
    );
  }

  async getSimilarProducts(productId, params) {
    return await this.instance.get(
      `${this.baseUrl}/public/related/${productId}?${this.generateQueryString(
        params
      )}`
    );
  }

  async getRecommendedProducts(params) {
    return await this.instance.get(
      `${this.baseUrl}/public/recommended?${this.generateQueryString(params)}`
    );
  }

  async getPublicAmount(from) {
    if (!Object.keys(from).length) {
      return await this.instance.get(`${this.baseUrl}/amount/public`);
    }

    return await this.instance.get(
      `${this.baseUrl}/amount/public?${this.generateQueryString(from)}`
    );
  }

  async getAmount() {
    return await this.instance.get(`${this.baseUrl}/amount`);
  }

  async getFreeAmount() {
    return await this.instance.get(`${this.baseUrl}/amount/free`);
  }

  async getDashboardTotals() {
    return await this.instance.get(`${this.dashboardUrl}/totals`);
  }

  async getDashboardGraph(param) {
    return await this.instance.get(`${this.dashboardUrl}/graph?${this.generateQueryString(param)}`);
  }

  async getSalesProducts(params) {
    return await this.instance.get(`${this.dashboardUrl}/recents?${this.generateQueryString(params)}`);
  }

  async getArchive(param) {
    return await this.instance.get(
      `${this.baseUrl}/archive?${this.generateQueryString(param)}`,
      // https://github.com/nodejs/help/issues/2550
      { responseType: "arraybuffer" }
    );
  }

  async getFreeProduct(params) {
    return await this.instance.post(
      `${this.baseUrl}/free/archive/${params.id}`,
      params,
      { responseType: "arraybuffer" }
    );
  }

  async getReadyProducts(params) {
    return await this.instance.get(
      `${this.baseUrl}?${this.generateQueryString(params)}`
    );
  }

  async updateProduct(id, formData) {
    this.setFormDataInterceptors();

    return await this.instance.put(
      `${this.baseUrl}/${id}`,
      this.getFormData(formData)
    );
  }

  async updateProductSettings(id, formData) {
    this.setFormDataInterceptors();

    return await this.instance.patch(
      `${this.baseUrl}/${id}`,
      this.getFormData(formData)
    );
  }

  async updateProductsSettings(params) {
    return await this.instance.patch(`${this.baseUrl}/batch`, params);
  }

  async deleteProduct(id) {
    return await this.instance.delete(`${this.baseUrl}/${id}`);
  }

  async deleteProducts(ids) {
    return await this.instance.delete(`${this.baseUrl}/batch`, { data: ids });
  }
}

const productsService = new ProductsService();

export default productsService;
